import ContentArea from '../Organisms/ContentArea/ContentArea';
import StartPageModel from './Models/StartPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import Grid from '../Atoms/Grids/Grid';
import { styled } from '../stitches.config';
import { useTopPadding } from '../Shared/Common/Helpers';
import { SiteLinksSearchStructuredData } from '../Molecules/StructuredData/SiteLinksSearchStructuredData';
import { StructuredStartPageData } from '../Molecules/StructuredData/StructuredStartPageData';

function StartPage() {
  const { contentArea } = useCurrentPage<StartPageModel>();
  const pageData = useCurrentPage<StartPageModel>();
  const usePadding = useTopPadding({ firstBlock: contentArea[0] });

  return (
    <main>
      <SiteLinksSearchStructuredData />
      <StructuredStartPageData page={pageData} />

      <GridWrapper useTopPadding={usePadding}>
        <Grid type={12} data-content-area>
          {Array.isArray(contentArea) && (
            <ContentArea childItems={contentArea} />
          )}
        </Grid>
      </GridWrapper>
    </main>
  );
}

export const GridWrapper = styled('div', {
  pb: 8,
  variants: {
    useTopPadding: {
      true: {
        pt: 8,
        '@mediaMinLarge': {
          pt: 16,
        },
      },
    },
  },
  '@mediaMinLarge': {
    pb: 16,
  },
});

export default StartPage;
