import React from 'react';
import { StructuredData } from './StructuredData';
import { SearchAction, WebSite, WithContext } from 'schema-dts';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';

type QueryAction = SearchAction & {
  'query-input': string;
};

function SiteLinksSearchStructuredData(): JSX.Element {
  const {
    staticPages: { searchPage },
    domain,
    languageRoute,
  } = useAppSettingsData();

  const action: QueryAction = {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: `${domain}${searchPage}?searchQuery={search_term_string}`,
    },
    'query-input': 'required name=search_term_string',
  };

  const data: WithContext<WebSite> = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `${domain}/${languageRoute}/`,
    potentialAction: action,
  };

  return <StructuredData json={data} />;
}

export { SiteLinksSearchStructuredData };
