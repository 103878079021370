import React from 'react';
import { WebPage, WithContext } from 'schema-dts';
import StartPageModel from '../../StartPage/Models/StartPageModel.interface';
import { StructuredData } from './StructuredData';

export interface StructuredPageDataProps {
  page: StartPageModel;
}

/** Renders structured product data using Schema.org vocab */
const StructuredStartPageData: React.FC<StructuredPageDataProps> = ({
  page,
}) => {
  const baseWebPageData: WithContext<WebPage> = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: page.metaTitle,
    description: page.metaDescription,
    primaryImageOfPage: page.openGraph.imageUrl,
    image: page.openGraph.imageUrl,
  };

  return <StructuredData json={baseWebPageData} />;
};

export { StructuredStartPageData };
